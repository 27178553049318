import React,{useEffect} from 'react';
import {Grid,Hidden,Typography} from '@material-ui/core';
import {TimelineMax,Sine,Linear, Power1} from "gsap/dist/gsap";
export default function Rocket() {

    useEffect(()=>{

        var tlStars = new TimelineMax({repeat:-1});
            tlStars.from("#star1",{scale:1.2,duration:1,rotation:360,transformOrigin:"center",ease:Power1.easeInOut})
            tlStars.from("#star1",{scale:1,duration:1,rotation:360,transformOrigin:"center",ease:Power1.easeInOut})
            tlStars.from("#star2",{scale:1.2,duration:1,rotation:360,transformOrigin:"center",ease:Power1.easeInOut},'-=1.5')
            tlStars.from("#star2",{scale:1,duration:1,rotation:360,transformOrigin:"center",ease:Power1.easeInOut},'-=.5')
            tlStars.from("#star3",{scale:1.3,duration:1,rotation:360,transformOrigin:"center",ease:Power1.easeInOut},'-=1.5')
            tlStars.from("#star3",{scale:1,duration:1,rotation:360,transformOrigin:"center",ease:Power1.easeInOut},'-=.5')
            tlStars.from("#star4",{scale:1.2,duration:1,rotation:360,transformOrigin:"center",ease:Power1.easeInOut},'-=1.5')
            tlStars.from("#star4",{scale:1.2,duration:1,rotation:360,transformOrigin:"center",ease:Power1.easeInOut},'-=.5')
            tlStars.from("#star5",{scale:1.2,duration:1,rotation:360,transformOrigin:"center",ease:Power1.easeInOut},'-=1.5')
            tlStars.from("#star5",{scale:1,duration:1,rotation:360,transformOrigin:"center",ease:Power1.easeInOut},'-=.5')
            tlStars.from("#star6",{scale:1.3,duration:1,rotation:360,transformOrigin:"center",ease:Power1.easeInOut},'-=1.5')
            tlStars.from("#star6",{scale:1,duration:1,rotation:360,transformOrigin:"center",ease:Power1.easeInOut},'-=.5')
        

        var tlrocketFly = new TimelineMax({yoyo:true,onComplete:()=>tlrocketFly.tweenFromTo("momentumZeroState1","momentunZeroState2",{repeat:-1})});
        tlrocketFly
            .set("#Rocket",{opacity:0,y:600})
            .to("#Rocket",{duration:2,opacity:1,y:0})
            .add("momentumZeroState1")
            .to("#Rocket",  {duration:.4,y:5, rotation:-.5, transformOrigin: "bottom center", ease:Sine.easeInOut})
            .to("#Rocket",  {duration:.4,y:2, rotation:.25, ease:Sine.easeInOut})
            .to("#Rocket",  {duration:.4,y:-2.5, rotation:-.25, ease:Sine.easeInOut})
            .to("#Rocket",  {duration:.4,y:6, rotation:.5, ease:Sine.easeInOut})
            .to("#Rocket",  {duration:.4,y:0, rotation:0, ease:Sine.easeInOut})
            .add("momentumZeroState2");


       
        
        var tlParticles= new TimelineMax({repeat:-1})
        tlParticles
                .set("#particle1", {y:-170, opacity:1})
                .set("#particle2", {y:-320, opacity:1})
                .set("#particle3", {y:-500, opacity:0.6})
                .set("#particle4", {y:-600, opacity:0.6})
                .set("#particle5", {y:-250, opacity:0.6})
                .set("#particle6", {y:-530, opacity:0.6})
                .set("#particle7", {y:-500, opacity:0.6})
                .set("#particle8", {y:-50, opacity:0.6})
                .set("#particle9", {y:-40, opacity:0.6})
                .set("#particle10", {y:-600, opacity:0.6})
                .set("#particle11", {y:-80, opacity:0.6})
                .set("#particle12", {y:-150, opacity:0.6})
                .to("#particle1", {y:390,duration:1,opacity:0,ease:Linear.easeNone})
                .to("#particle2", {y:170, duration:1,opacity:0,ease:Linear.easeNone},'-=0.3')
                .to("#particle3", {y:40, duration:1,opacity:0,ease:Linear.easeNone},'-=0.3')
                .to("#particle5", {y:290, duration:1,opacity:0,ease:Linear.easeNone},'-=1.5')
                .to("#particle6", {y:10, duration:1,opacity:0,ease:Linear.easeNone},'-=0.6')
                .to("#particle7", {y:40, duration:1,opacity:0,ease:Linear.easeNone},'-=0.8')
                .to("#particle8", {y:490, duration:1,opacity:0,ease:Linear.easeNone},'-=.2')
                .to("#particle6", {y:10, duration:1,opacity:0,ease:Linear.easeNone},'-=0.6')
                .to("#particle9", {y:500, duration:1,opacity:0,ease:Linear.easeNone},'+=0.1')
                .to("#particle10", {y:0, duration:1,opacity:0,ease:Linear.easeNone})
                .to("#particle4", {y:10, duration:1,opacity:0,ease:Linear.easeNone},'-=1.5')
                .to("#particle11", {y:460, duration:1,opacity:0,ease:Linear.easeNone},'-=0.6')
                .to("#particle12", {y:390, duration:1,opacity:0,ease:Linear.easeNone},'-=2.6')
        var tlPlanets= new TimelineMax({repeat:-1})
        tlPlanets
            .set("#planet1",{opacity:1,y:-100,x:100})
            .set("#planet2",{opacity:1,y:-80,x:-40})
            .to("#planet1",{duration:25,y:600,x:63,opacity:0,transformOrigin:"center",ease:Linear.easeNone})
            .to("#planet2",{duration:15,y:600,x:43,opacity:0,transformOrigin:"center",ease:Linear.easeNone},'+=9')

        var tlAstronauts= new TimelineMax({repeat:-1})
            tlAstronauts.set("#astronaut1",{opacity:1,y:-100,x:50})
            tlAstronauts.set("#astronaut2",{opacity:1,y:-100,x:80})
            tlAstronauts.to("#astronaut1",{opacity:0,y:600,x:160,duration:13,rotation:360,transformOrigin:"center",ease:Linear.easeNone})
            tlAstronauts.to("#astronaut2",{opacity:0,y:300,x:-200,duration:9,rotation:260,transformOrigin:"center",ease:Linear.easeNone},'+=4');
            
        var tlTitle=new TimelineMax();
        tlTitle
            .set(".title",{opacity:0})
            .to(".title",{duration:2,opacity:1,ease:Power1.easeInOut})

        var tlRocketFire = new TimelineMax({repeat:-1});
        tlRocketFire
            .to("#innerFire",{scale:1.2,opacity:0.8,transformOrigin:"top",duration:0.4,ease:Sine.easeInOut})
            .to("#outerFire",{scale:1.2,opacity:0.6,transformOrigin:"top",duration:0.4,ease:Sine.easeInOut},'-=0.4')
            .to("#innerFire",{scale:1,opacity:1,duration:0.6,ease:Sine.easeInOut},)
            .to("#outerFire",{scale:1,opacity:1,duration:0.6,ease:Sine.easeInOut},'-=0.6')
        
               
    },[])

    return (
            <div>
                <Grid container direction="row" justify="center" alignItems="center" style={{minHeight:"100vh"}}>
                    <Grid item sm={12} md={7}>
                        <svg width="100%" height="540" viewBox="0 0 350 626.97">
                            <title>RocketImage</title>
                            <g style={{isolation:"isolate"}}>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="RocketImage">
                                        <g id="Background">
                                            <g style={{enableBackground:"new 0 0 300 600", overflow:"hidden",height:"300px"}}>
                                            <circle id="particle1" cx="209.09" cy="158.16" r="5.62" style={{fill:"#f1f1f1"}}/>
                                            <circle id="particle2" cx="321.48" cy="302.28" r="2" style={{fill:"#f1f1f1"}}/>
                                            <circle id="particle3" cx="320.25" cy="495.04" r="1.23" style={{fill:"#f1f1f1"}}/>
                                            <circle id="particle4" cx="40.07" cy="587.11" r="1.53" style={{fill:"#f1f1f1"}}/>
                                            <circle id="particle5" cx="45.87" cy="244.45" r="4.27" style={{fill:"#f1f1f1"}}/>
                                            <circle id="particle6" cx="139.04" cy="519.89" r="2.01" style={{fill:"#f1f1f1"}}/>
                                            <circle id="particle7" cx="216.52" cy="460.78" r="3.06" style={{fill:"#f1f1f1"}}/>
                                            <circle id="particle8" cx="252.6" cy="33.41" r="7.9" style={{fill:"#f1f1f1"}}/>
                                            <circle id="particle9" cx="33.63" cy="25.51" r="6.44" style={{fill:"#f1f1f1"}}/>
                                            <circle id="particle10" cx="234.73" cy="571.93" r="6.11" style={{fill:"#f1f1f1"}}/>
                                            <circle id="particle11" cx="151.58" cy="70.97" r="4.14" style={{fill:"#f1f1f1"}}/>
                                            <circle id="particle12" cx="24.2" cy="133.19" r="2.99" style={{fill:"#f1f1f1"}}/>
                                        </g>
                                        <g id="Stars">
                                            <path id="star1" d="M339.82,51.48a24.5,24.5,0,0,0,4.36-.59,25.13,25.13,0,0,0-4.36-.58,14.42,14.42,0,0,0,1.48-1.88,16.41,16.41,0,0,0-2,1.36,20,20,0,0,0-.63-4,20.37,20.37,0,0,0-.64,4,15.94,15.94,0,0,0-2-1.36,13.75,13.75,0,0,0,1.47,1.88,25.33,25.33,0,0,0-4.36.58,24.69,24.69,0,0,0,4.36.59A14.09,14.09,0,0,0,336,53.35,15.15,15.15,0,0,0,338,52a20.35,20.35,0,0,0,.64,4,20,20,0,0,0,.63-4,15.58,15.58,0,0,0,2,1.35A14.8,14.8,0,0,0,339.82,51.48Z" style={{fill:"#f1f1f1"}}/>
                                            <path id="star2" d="M253.8,115.07a25.64,25.64,0,0,0,4.35-.59,25.1,25.1,0,0,0-4.35-.58,15.7,15.7,0,0,0,1.47-1.88,15.13,15.13,0,0,0-2,1.36,20,20,0,0,0-.63-4,20.37,20.37,0,0,0-.64,4,14.72,14.72,0,0,0-2-1.36,15.7,15.7,0,0,0,1.47,1.88,25.1,25.1,0,0,0-4.35.58,25.64,25.64,0,0,0,4.35.59,15.22,15.22,0,0,0-1.47,1.87,15.15,15.15,0,0,0,2-1.35,20.35,20.35,0,0,0,.64,4,20,20,0,0,0,.63-4,15.58,15.58,0,0,0,2,1.35A15.22,15.22,0,0,0,253.8,115.07Z" style={{fill:"#f1f1f1"}}/>
                                            <path id="star3" d="M93.45,149.31a25.64,25.64,0,0,0,4.35-.59,25.1,25.1,0,0,0-4.35-.58,14.82,14.82,0,0,0,1.47-1.88,16.41,16.41,0,0,0-2,1.36,20,20,0,0,0-.63-4,20.37,20.37,0,0,0-.64,4,15.94,15.94,0,0,0-2-1.36,13.75,13.75,0,0,0,1.47,1.88,25.33,25.33,0,0,0-4.36.58,25.87,25.87,0,0,0,4.36.59,14.09,14.09,0,0,0-1.47,1.87,15.15,15.15,0,0,0,2-1.35,20.35,20.35,0,0,0,.64,4,20,20,0,0,0,.63-4,15.58,15.58,0,0,0,2,1.35A15.22,15.22,0,0,0,93.45,149.31Z" style={{fill:"#f1f1f1"}}/>
                                            <path id="star4" d="M63.9,386.82a25.33,25.33,0,0,0,4.36-.58,25.87,25.87,0,0,0-4.36-.59,14.09,14.09,0,0,0,1.47-1.87,15.15,15.15,0,0,0-2,1.35,20.35,20.35,0,0,0-.64-4,20,20,0,0,0-.63,4,15.58,15.58,0,0,0-2-1.35,15.22,15.22,0,0,0,1.47,1.87,25.64,25.64,0,0,0-4.35.59,25.1,25.1,0,0,0,4.35.58A14.82,14.82,0,0,0,60,388.7a16.41,16.41,0,0,0,2-1.36,20,20,0,0,0,.63,4,20.37,20.37,0,0,0,.64-4,15.94,15.94,0,0,0,2,1.36A13.75,13.75,0,0,0,63.9,386.82Z" style={{fill:"#f1f1f1"}}/>
                                            <path id="star5" d="M235.92,310a25.33,25.33,0,0,0,4.36-.58,25.87,25.87,0,0,0-4.36-.59,14.8,14.8,0,0,0,1.48-1.87,15.58,15.58,0,0,0-2,1.35,20,20,0,0,0-.63-4,20.35,20.35,0,0,0-.64,4,15.93,15.93,0,0,0-2-1.35,14.09,14.09,0,0,0,1.47,1.87,25.87,25.87,0,0,0-4.36.59,25.33,25.33,0,0,0,4.36.58,13.75,13.75,0,0,0-1.47,1.88,16.81,16.81,0,0,0,2-1.36,20.37,20.37,0,0,0,.64,4,20,20,0,0,0,.63-4,16.41,16.41,0,0,0,2,1.36A14.42,14.42,0,0,0,235.92,310Z" style={{fill:"#f1f1f1"}}/>
                                            <path id="star6" d="M315.89,153.13a25.87,25.87,0,0,0,4.36-.59,25.33,25.33,0,0,0-4.36-.58,13.75,13.75,0,0,0,1.47-1.88,16.81,16.81,0,0,0-2,1.36,19.52,19.52,0,0,0-.64-4,20,20,0,0,0-.63,4,16.81,16.81,0,0,0-2-1.36A13.75,13.75,0,0,0,313.5,152a25.33,25.33,0,0,0-4.36.58,25.87,25.87,0,0,0,4.36.59A14.09,14.09,0,0,0,312,155a15.93,15.93,0,0,0,2-1.35,20,20,0,0,0,.63,4,19.5,19.5,0,0,0,.64-4,15.93,15.93,0,0,0,2,1.35A14.09,14.09,0,0,0,315.89,153.13Z" style={{fill:"#f1f1f1"}}/>
                                        </g>
                                        <g id="planet2" style={{opacity:"0.85"}}>
                                            <circle cx="109.12" cy="37.74" r="29.15" transform="translate(-5.9 27.69) rotate(-14.09)" style={{fill:"#708aff"}}/>
                                            <path d="M124.4,12.33a29.51,29.51,0,0,0-23.14-3.17,29.55,29.55,0,0,0,8.08,43.65A29.55,29.55,0,0,0,132.48,56a29.55,29.55,0,0,0-8.08-43.65Z" style={{fill:"#9cd4c9"}}/>
                                            <path d="M112.54,67.19s23.36-20.67,19.17-48.62a29.16,29.16,0,0,0-9.35-7.36C128.91,41.15,100,66,100,66S106.94,68.36,112.54,67.19Z" style={{fill:"#8cc4b9"}}/>
                                            <path d="M90.93,61.16s24.71-20.07,21.6-52.88c-5.11-.79-11.27.88-11.27.88,2,26.19-17.82,43.42-17.82,43.42A28.74,28.74,0,0,0,90.93,61.16Z" style={{fill:"#8cc4b9"}}/>
                                            <path d="M109.34,52.81a29.55,29.55,0,0,1-8.08-43.65A29.64,29.64,0,1,0,132.48,56,29.55,29.55,0,0,1,109.34,52.81Z" style={{fill:"#7db5aa"}}/>
                                            <path d="M141.18,14.62c-1.75-2.46-6.82-2.2-13.61.19-7.07,2.5-16,7.3-25.07,13.79-1,.7-1.94,1.41-2.89,2.12A102.54,102.54,0,0,0,81.37,47.88C76.91,53.54,75,58.25,76.8,60.71s6.82,2.19,13.62-.2l-.06,0-.23-.19-.06-.05L89.75,60l-.28-.24,0,0A31.93,31.93,0,0,1,86.79,57h0c-2.18.52-3.67.44-4.25-.37s-.19-2.26,1-4.15h0C85.8,48.89,91,43.7,97.77,38.18c2.33-1.88,4.84-3.8,7.51-5.71,10.49-7.51,20.4-12.8,25.93-14.13,2.18-.53,3.68-.44,4.26.37s.18,2.25-1,4.14h0a29.52,29.52,0,0,1,2.16,4.58h0C141.07,21.78,142.94,17.07,141.18,14.62Z" style={{fill:"#f7e1b0"}}/>
                                        </g>
                                        <g id="planet1" style={{opacity:"0.87"}}>
                                            <circle cx="198.98" cy="35.13" r="31.48" transform="translate(1.39 77.36) rotate(-22.08)" style={{fill:"#708aff"}}/>
                                            <path d="M189.77,48.21A31.47,31.47,0,0,1,206.47,7a30.86,30.86,0,0,1,3.6-1.27,31.47,31.47,0,1,0-2.41,59.7A31.37,31.37,0,0,1,189.77,48.21Z" style={{fill:"#cacccc"}}/>
                                            <path d="M228,22.84A31.38,31.38,0,0,0,210.07,5.68,30.86,30.86,0,0,0,206.47,7a31.47,31.47,0,0,0,1.19,58.43,33,33,0,0,0,3.61-1.27A31.48,31.48,0,0,0,228,22.84Z" style={{fill:"#f0f2f2"}}/>
                                            <path d="M214,43.51a1,1,0,1,1-1.33-.54A1,1,0,0,1,214,43.51Z" style={{fill:"#7c7c7c"}}/>
                                            <path d="M211.22,54.55a2.69,2.69,0,1,1-3.52-1.42A2.7,2.7,0,0,1,211.22,54.55Z" style={{fill:"#7c7c7c"}}/>
                                            <path d="M214.28,25a5,5,0,1,1-6.54-2.64A5,5,0,0,1,214.28,25Z" style={{fill:"#7c7c7c"}}/>
                                            <path d="M196.47,37.39a1,1,0,0,1-.54,1.33,1,1,0,1,1,.54-1.33Z" style={{fill:"#7c7c7c"}}/>
                                            <path d="M212.37,16a1,1,0,1,1-1.33-.54A1,1,0,0,1,212.37,16Z" style={{fill:"#7c7c7c"}}/>
                                            <path d="M226.16,31.55a1.24,1.24,0,1,1-2.4.23,1.24,1.24,0,1,1,2.4-.23Z" style={{fill:"#7c7c7c"}}/>
                                            <path d="M181.16,34.07a1.4,1.4,0,1,1-1.83-.74A1.4,1.4,0,0,1,181.16,34.07Z" style={{fill:"#7c7c7c"}}/>
                                            <path d="M175,45a.92.92,0,1,1-1.2-.49A.91.91,0,0,1,175,45Z" style={{fill:"#7c7c7c"}}/>
                                            <path d="M182.21,18.87a2.43,2.43,0,1,1-4.58-1.34,2.43,2.43,0,1,1,4.58,1.34Z" style={{fill:"#7c7c7c"}}/>
                                            <path d="M189.15,57.29c1.92,1.58,2.68,3.83,1.7,5s-3.34.89-5.26-.68-2.68-3.83-1.7-5S187.23,55.72,189.15,57.29Z" style={{fill:"#7c7c7c"}}/>
                                        </g>
                                        <g id="astronaut2">
                                            <path d="M204.08,76.78,198,79a6.11,6.11,0,0,1-7.84-3.82l-4-11.59a6.39,6.39,0,0,1,3.71-8.07l6.08-2.24a6.11,6.11,0,0,1,7.84,3.82l4,11.59A6.39,6.39,0,0,1,204.08,76.78Z" style={{fill:"#959ca0"}}/>
                                            <path d="M206.74,45.6a2.24,2.24,0,0,0-2.33-.14,6.24,6.24,0,0,0-1.89,1.59c-.77.87-1.51,1.77-2.21,2.69a7.49,7.49,0,0,1-2.13,2.37c-.39.22-3.8,1.84-5.47,2.63-1.78.48-5.42,1.45-5.85,1.54a7.08,7.08,0,0,1-3.1-.45c-1.12-.25-2.24-.46-3.37-.63a6.08,6.08,0,0,0-2.44,0,2.35,2.35,0,0,0-1.72,1.63,2.73,2.73,0,0,0,.16,1.49,7.63,7.63,0,0,0,4.86,4.31,64,64,0,0,0,6.48,1.46c1.2.29,1.91,4.39,2.31,5.54a2.6,2.6,0,0,0,.4.84c.58.7,1.66.57,2.53.35A70.82,70.82,0,0,0,201.16,68a5.46,5.46,0,0,0,2.94-2,1.44,1.44,0,0,0,.22-.83,1.94,1.94,0,0,0-.38-.87c-.89-1.4-2.47-3.91-1.62-5.56,1-2,3-3.45,4.11-5.36a8,8,0,0,0,1.11-6.5A2.73,2.73,0,0,0,206.74,45.6Z" style={{fill:"#f9fbfc"}}/>
                                            <path d="M191.24,57.43l-.07,0Z" style={{fill:"#102638"}}/>
                                            <path d="M191.17,57.46l-.06,0Z" style={{fill:"#102638"}}/>
                                            <path d="M188.66,78a7.89,7.89,0,0,0-.48,2.5,8.14,8.14,0,0,0,.22.95c.27,1.12-.26,2.1,0,3.19a3.37,3.37,0,0,0,2.47,2.48,4,4,0,0,0,3.17-1.3c2.86-2.65,4-6.66,5.25-10.43.15-.48.36-1,.82-1.18a1.48,1.48,0,0,1,1.31.38c2.38,1.7,4.19,4.32,6.92,5.34a6.78,6.78,0,0,0,4.47.08A3.54,3.54,0,0,0,215,78.35c1.33-1.43-.89-4.15-1.45-5.38a10.73,10.73,0,0,0-1-1.86c-1.31-1.8-3.57-2.73-5.21-4.18s-3.69-2.89-5.9-3a10.15,10.15,0,0,0-3.62.75c-2.5.84-5.3,1.82-7,4-.88,1.12-.84,2.47-1.1,3.86s-.56,3-.83,4.46C188.85,77.34,188.76,77.67,188.66,78Z" style={{fill:"#f9fbfc"}}/>
                                            <path d="M194.6,84.45a3.1,3.1,0,0,0-1.12-1.21,10.43,10.43,0,0,0-4.09-1.37c-.38-.06-.84-.1-1.08.21a1.14,1.14,0,0,0-.19.57,9.89,9.89,0,0,1-.37,2.25,2.12,2.12,0,0,1-1.57,1.47,13.1,13.1,0,0,1-1.63-.18,2.93,2.93,0,0,0-1.42.65,5.39,5.39,0,0,1,3.85.9c1.37.81,2.68,1.84,4.21,2.09.54.1,1.1.08,1.64.23a2.1,2.1,0,0,1,1,.59c0-.23,0-.44,0-.61a9.09,9.09,0,0,1,.74-2.78A3.76,3.76,0,0,0,194.6,84.45Z" style={{fill:"#d1dfe5"}}/>
                                            <path d="M192.83,90.06c-.54-.15-1.1-.13-1.64-.23-1.53-.25-2.84-1.28-4.21-2.09a5.39,5.39,0,0,0-3.85-.9c-.75.54-1.45,1.33-1.38,1.93.08.82,1.93,1.36,2.54,1.76a14.84,14.84,0,0,0,6.34,2.32,4.44,4.44,0,0,0,1.78,0c.57-.16,1.15-.19,1.34-.77a4,4,0,0,0,0-1.4A2.1,2.1,0,0,0,192.83,90.06Z" style={{fill:"#b4bcc1"}}/>
                                            <path d="M182.94,91.18a4,4,0,0,1-1.05-1.32,1.67,1.67,0,0,1,.79-2.34c1.34-.66,2.94-.11,4.26.64a12.39,12.39,0,0,0,4,2c.53.09,1.07.07,1.58.21a1.62,1.62,0,0,1,1.19,1,1.82,1.82,0,0,1-1.12,2C189.94,94.69,185.16,93.13,182.94,91.18Z" style={{fill:"#959ca0"}}/>
                                            <path d="M210,79.25a9.48,9.48,0,0,1,.78-2.6,13.37,13.37,0,0,1,1.92-2.74c.26-.31.59-.63,1-.55a1,1,0,0,1,.49.32,9.45,9.45,0,0,0,1.68,1.49,2,2,0,0,0,2.11.12c.47-.31.73-.85,1.15-1.21a3.05,3.05,0,0,1,1.5-.42,5.63,5.63,0,0,0-2.42,3.21,14,14,0,0,1-2,4.37,16.48,16.48,0,0,0-1.12,1.24,2.11,2.11,0,0,0-.39,1.08,5.76,5.76,0,0,0-.35-.49c-1-1.32-2.77-1.92-4.24-2.45a.63.63,0,0,1-.21-.12.5.5,0,0,1,0-.4C209.87,79.81,209.92,79.53,210,79.25Z" style={{fill:"#d1dfe5"}}/>
                                            <path d="M215.06,82.48a16.48,16.48,0,0,1,1.12-1.24,14,14,0,0,0,2-4.37,5.63,5.63,0,0,1,2.42-3.21c.92-.07,1.94.08,2.26.59.44.69-.66,2.31-.88,3a15.72,15.72,0,0,1-3.48,5.93,4.59,4.59,0,0,1-1.39,1.14c-.55.25-1,.61-1.52.28a3.87,3.87,0,0,1-.89-1.06A2.11,2.11,0,0,1,215.06,82.48Z" style={{fill:"#b4bcc1"}}/>
                                            <path d="M223.39,76.89a4.06,4.06,0,0,0,0-1.7,1.84,1.84,0,0,0-1-1.27,1.72,1.72,0,0,0-1,0c-1.44.36-2.34,1.83-2.9,3.27a13.36,13.36,0,0,1-1.92,4.15,13.72,13.72,0,0,0-1.09,1.2,1.7,1.7,0,0,0-.3,1.56,1.72,1.72,0,0,0,2.07.78C220.14,84.18,222.87,79.85,223.39,76.89Z" style={{fill:"#959ca0"}}/>
                                            <path d="M173.18,52.23c.08-.63.87-1.15,1.32-.72a1.32,1.32,0,0,1,.29.5,8.19,8.19,0,0,0,.76,1.74,2.15,2.15,0,0,0,1.48,1,4.17,4.17,0,0,1,1.28.07.36.36,0,0,1,.19.15.5.5,0,0,1,0,.38,19.56,19.56,0,0,0-.62,5.75,3.5,3.5,0,0,1-2.09-.53,5,5,0,0,0-1.12-.68,3.33,3.33,0,0,0-1.22,0,7.85,7.85,0,0,1-3.38-.59c-.41-.18-.86-.47-.88-.93a1.9,1.9,0,0,0,0-.53,1,1,0,0,0-.29-.38,1.62,1.62,0,0,1-.57-1.44c.11-.54.74-.94,1.19-.66a3,3,0,0,1-.65-1,1,1,0,0,1,.3-1.12c.52-.36,1.18.08,1.72.4s1.64.87,2.16.63C174,53.81,173.09,53,173.18,52.23Z" style={{fill:"#d1dfe5"}}/>
                                            <path d="M206,40.14c-.45-.44-1.38-.32-1.47.31a1.24,1.24,0,0,0,.09.57,8.6,8.6,0,0,1,.48,1.84,2.22,2.22,0,0,1-.51,1.77c-.3.32-.74.51-.94.9a.39.39,0,0,0-.06.24.54.54,0,0,0,.23.29,19.11,19.11,0,0,1,4,4,3.63,3.63,0,0,0,1.28-1.79,4.93,4.93,0,0,1,.46-1.25,3.61,3.61,0,0,1,.91-.83,8,8,0,0,0,2.25-2.66,1.26,1.26,0,0,0,.11-1.29c-.1-.14-.27-.24-.34-.4a1,1,0,0,1,0-.48,1.66,1.66,0,0,0-.45-1.49.86.86,0,0,0-1.33.27,3,3,0,0,0-.14-1.23,1,1,0,0,0-.92-.67c-.62.06-.86.83-1.09,1.43s-.72,1.74-1.27,1.89C206.37,41.89,206.57,40.68,206,40.14Z" style={{fill:"#d1dfe5"}}/>
                                            <path d="M187.46,61.57a23.83,23.83,0,0,1-.46,5,.94.94,0,0,0,0,.55.66.66,0,0,0,.5.32,1.56,1.56,0,0,0,1.35-.58,4.25,4.25,0,0,0,.73-1.4,15.43,15.43,0,0,0,.63-7.37,1.67,1.67,0,0,0-1.51-1.61l-1.29-.37a1.59,1.59,0,0,0-.83-.09c-1,.26.05,1.6.25,2.15A11.33,11.33,0,0,1,187.46,61.57Z" style={{fill:"#d1dfe5"}}/>
                                            <path d="M201.16,56.53a22.73,22.73,0,0,0,3.45,3.55.85.85,0,0,1,.33.44.66.66,0,0,1-.2.57,1.6,1.6,0,0,1-1.42.44,4.12,4.12,0,0,1-1.44-.6,14.49,14.49,0,0,1-5.07-5.27,2.19,2.19,0,0,1-.34-.91,2.29,2.29,0,0,1,.53-1.33l.78-1.13a1.88,1.88,0,0,1,.6-.62c.94-.45,1,1.27,1.14,1.82A10.9,10.9,0,0,0,201.16,56.53Z" style={{fill:"#d1dfe5"}}/>
                                            <path d="M194,61.61a1.28,1.28,0,0,0-1.64-.8,1.34,1.34,0,0,0-.77,1.68,1.27,1.27,0,0,0,1.63.8A1.33,1.33,0,0,0,194,61.61Z" style={{fill:"#3a464c"}}/>
                                            <path d="M198,60.15a.65.65,0,0,0-.84-.41.69.69,0,0,0-.41.87.66.66,0,0,0,.85.42A.69.69,0,0,0,198,60.15Z" style={{fill:"#3a464c"}}/>
                                            <path d="M188.55,79.28a2.1,2.1,0,0,1,1.26-.18,6.18,6.18,0,0,1,1.24.34" style={{fill:"none",stroke:"#d1dfe5",strokeLinecap:"round",strokeMiterLimit:"10",strokeWidth:"0.41464965923842684px"}}/>
                                            <path d="M188.92,78a2.38,2.38,0,0,1,1.32-.16,5.47,5.47,0,0,1,1.29.39" style={{fill:"none",stroke:"#d1dfe5",strokeLinecap:"round",strokeMiterLimit:"10",strokeWidth:"0.41464965923842684px"}}/>
                                            <path d="M189.25,77a3.3,3.3,0,0,1,2.4.37" style={{fill:"none",stroke:"#d1dfe5",strokeLinecap:"round",strokeMiterLimit:"10",strokeWidth:"0.41464965923842684px"}}/>
                                            <path d="M210.91,70.44A5.08,5.08,0,0,0,209,72.55" style={{fill:"none",stroke:"#d1dfe5",strokeLinecap:"round",strokeMiterLimit:"10",strokeWidth:"0.41464965923842684px"}}/>
                                            <path d="M209.9,69.57a5,5,0,0,0-1.83,2.31" style={{fill:"none",stroke:"#d1dfe5",strokeLinecap:"round",strokeMiterLimit:"10",strokeWidth:"0.41464965923842684px"}}/>
                                            <path d="M209,69.13a8.86,8.86,0,0,0-1.83,2.25" style={{fill:"none",stroke:"#d1dfe5",strokeLinecap:"round",strokeMiterLimit:"10",strokeWidth:"0.41464965923842684px"}}/>
                                            <path d="M189.26,58.68a5.17,5.17,0,0,0,2.84.51c.27,0,.54-.1.81-.16a7.26,7.26,0,0,0,3-1.33,7.85,7.85,0,0,0,.81-.82,5.75,5.75,0,0,0,1.14-1.71,6.09,6.09,0,0,0,.25-1.9,23.87,23.87,0,0,1-2.83.83l-2.82.87-2.88,1-1.4.49c-.24.08-1.09.22-1.15.52s.73.81.94,1A7.14,7.14,0,0,0,189.26,58.68Z" style={{fill:"#3a464c",opacity:"0.25"}}/>
                                            <path d="M198.53,47.61a7.63,7.63,0,0,0-9.83-4.79A8,8,0,0,0,184,53a7.64,7.64,0,0,0,9.84,4.8A8,8,0,0,0,198.53,47.61Z" style={{fill:"#f9fbfc"}}/>
                                            <path d="M196.47,46.06l-.26-.07c-1.07-.18-1.94.7-2.85,1.1s-1.85.78-2.8,1.1-1.88.7-2.84,1-2.17.17-2.88,1l-.16.23a3.13,3.13,0,0,0-.07,2.58,6.71,6.71,0,0,0,8.74,4.12l.2-.07.07,0,.07,0,.2-.07a7,7,0,0,0,4.22-8.9A3,3,0,0,0,196.47,46.06Z" style={{fill:"#102638"}}/>
                                            <path d="M191.63,48.22s.51,5.43-1.22,8.9A5.55,5.55,0,0,0,193,57s2.37-4.85.83-9.69C193.82,47.26,192.3,48,191.63,48.22Z" style={{fill:"#f9fbfc",opacity:"0.15"}}/>
                                            <path d="M194.5,47s1.54,5.39-.82,9.69a3.84,3.84,0,0,0,1.28-.63s2.8-3.63.46-9.62Z" style={{fill:"#f9fbfc",opacity:"0.15"}}/>
                                        </g>
                                        <g id="astronaut1">
                                            <path d="M51.25,66.7l6.08,2.24a6.09,6.09,0,0,0,7.83-3.82l4-11.59a6.39,6.39,0,0,0-3.71-8.07l-6.09-2.24A6.1,6.1,0,0,0,51.56,47l-4,11.6A6.36,6.36,0,0,0,51.25,66.7Z" style={{fill:"#959ca0"}}/>
                                            <path d="M48.59,35.52a2.22,2.22,0,0,1,2.33-.13A6.21,6.21,0,0,1,52.8,37c.78.86,1.52,1.76,2.22,2.68A7.62,7.62,0,0,0,57.14,42c.39.21,3.81,1.84,5.48,2.62,1.77.48,5.42,1.46,5.85,1.55a7.08,7.08,0,0,0,3.1-.46c1.11-.25,2.24-.46,3.37-.63a6.26,6.26,0,0,1,2.44,0,2.37,2.37,0,0,1,1.72,1.63,2.72,2.72,0,0,1-.16,1.49,7.62,7.62,0,0,1-4.87,4.31A64.41,64.41,0,0,1,67.6,54c-1.2.3-1.92,4.39-2.31,5.54a2.53,2.53,0,0,1-.41.84c-.58.7-1.65.57-2.52.35a71,71,0,0,1-8.2-2.84,5.34,5.34,0,0,1-2.93-2,1.45,1.45,0,0,1-.23-.83,2.05,2.05,0,0,1,.38-.87c.89-1.4,2.48-3.91,1.63-5.56-1-2-3-3.45-4.11-5.35a8,8,0,0,1-1.11-6.51A2.66,2.66,0,0,1,48.59,35.52Z" style={{fill:"#f9fbfc"}}/>
                                            <path d="M64.08,47.36l.07,0Z" style={{fill:"#102638"}}/>
                                            <path d="M64.15,47.38l.07,0Z" style={{fill:"#102638"}}/>
                                            <path d="M66.67,67.93a7.65,7.65,0,0,1,.47,2.5,5,5,0,0,1-.22.95c-.27,1.12.27,2.1,0,3.19a3.38,3.38,0,0,1-2.47,2.48,4,4,0,0,1-3.17-1.3c-2.87-2.64-4-6.66-5.25-10.43-.15-.48-.36-1-.82-1.18a1.54,1.54,0,0,0-1.32.38c-2.37,1.71-4.19,4.32-6.91,5.34a6.79,6.79,0,0,1-4.47.08,3.56,3.56,0,0,1-2.18-1.66c-1.33-1.43.89-4.16,1.45-5.39a9.7,9.7,0,0,1,1-1.85c1.3-1.81,3.56-2.74,5.2-4.18s3.7-2.89,5.9-3a10.65,10.65,0,0,1,3.63.75c2.49.84,5.3,1.82,7,4,.88,1.12.84,2.48,1.1,3.86s.55,3,.83,4.46C66.48,67.26,66.57,67.59,66.67,67.93Z" style={{fill:"#f9fbfc"}}/>
                                            <path d="M60.72,74.37a3.13,3.13,0,0,1,1.13-1.21,10.43,10.43,0,0,1,4.08-1.36c.38-.07.85-.11,1.09.2a1.13,1.13,0,0,1,.18.57,10.08,10.08,0,0,0,.38,2.26,2.11,2.11,0,0,0,1.57,1.46,12.74,12.74,0,0,0,1.62-.18,3,3,0,0,1,1.42.65,5.37,5.37,0,0,0-3.85.91c-1.36.8-2.68,1.83-4.2,2.09A14.71,14.71,0,0,0,62.5,80a2,2,0,0,0-1,.58c0-.22,0-.44,0-.61a8.5,8.5,0,0,0-.74-2.77A3.83,3.83,0,0,1,60.72,74.37Z" style={{fill:"#d1dfe5"}}/>
                                            <path d="M62.5,80a14.71,14.71,0,0,1,1.64-.23c1.52-.26,2.84-1.29,4.2-2.09a5.37,5.37,0,0,1,3.85-.91c.76.55,1.45,1.33,1.39,1.93-.09.83-1.94,1.36-2.54,1.77a15,15,0,0,1-6.34,2.31,4.44,4.44,0,0,1-1.78,0c-.58-.15-1.16-.19-1.35-.77a4.29,4.29,0,0,1,0-1.4A2,2,0,0,1,62.5,80Z" style={{fill:"#b4bcc1"}}/>
                                            <path d="M72.39,81.1a3.78,3.78,0,0,0,1-1.31,1.87,1.87,0,0,0,0-1.65,1.69,1.69,0,0,0-.78-.69c-1.33-.66-2.94-.12-4.25.64a12.52,12.52,0,0,1-4,2c-.52.09-1.06.07-1.57.21a1.66,1.66,0,0,0-1.2,1,1.83,1.83,0,0,0,1.12,2C65.39,84.61,70.16,83.05,72.39,81.1Z" style={{fill:"#959ca0"}}/>
                                            <path d="M45.36,69.17a9.41,9.41,0,0,0-.78-2.59,13.21,13.21,0,0,0-1.93-2.75c-.25-.3-.58-.63-1-.55a1.08,1.08,0,0,0-.5.32,9.49,9.49,0,0,1-1.67,1.5,2.05,2.05,0,0,1-2.12.11c-.46-.3-.72-.84-1.15-1.2a3,3,0,0,0-1.49-.43,5.69,5.69,0,0,1,2.42,3.22c.56,1.51.94,3.16,2,4.36a16.63,16.63,0,0,1,1.12,1.25,2.07,2.07,0,0,1,.38,1.07L41,73c1-1.32,2.78-1.92,4.24-2.44a.42.42,0,0,0,.21-.13.5.5,0,0,0,0-.4C45.45,69.73,45.41,69.45,45.36,69.17Z" style={{fill:"#d1dfe5"}}/>
                                            <path d="M40.27,72.41a16.63,16.63,0,0,0-1.12-1.25c-1-1.2-1.41-2.85-2-4.36a5.69,5.69,0,0,0-2.42-3.22c-.92-.07-1.95.08-2.27.59-.43.7.67,2.31.89,3a15.74,15.74,0,0,0,3.48,5.93,4.46,4.46,0,0,0,1.39,1.13c.54.26,1,.61,1.52.29a3.9,3.9,0,0,0,.88-1.07A2.07,2.07,0,0,0,40.27,72.41Z" style={{fill:"#b4bcc1"}}/>
                                            <path d="M31.94,66.81a3.9,3.9,0,0,1,0-1.7,1.78,1.78,0,0,1,1-1.26,1.72,1.72,0,0,1,1,0c1.44.36,2.34,1.83,2.89,3.27a13.55,13.55,0,0,0,1.93,4.16,13.73,13.73,0,0,1,1.09,1.19,1.49,1.49,0,0,1-1.77,2.35C35.19,74.1,32.46,69.77,31.94,66.81Z" style={{fill:"#959ca0"}}/>
                                            <path d="M82.15,42.15c-.08-.63-.88-1.15-1.33-.72a1.3,1.3,0,0,0-.28.5,8.73,8.73,0,0,1-.76,1.74,2.12,2.12,0,0,1-1.49,1,4.55,4.55,0,0,0-1.28.07.36.36,0,0,0-.19.53,19.94,19.94,0,0,1,.63,5.76,3.6,3.6,0,0,0,2.09-.54,4.79,4.79,0,0,1,1.12-.68,3.56,3.56,0,0,1,1.22,0,7.7,7.7,0,0,0,3.38-.59c.41-.18.86-.47.87-.93a2.35,2.35,0,0,1,0-.53.86.86,0,0,1,.29-.37A1.71,1.71,0,0,0,87,45.94a.88.88,0,0,0-1.2-.66,2.92,2.92,0,0,0,.66-1,1,1,0,0,0-.31-1.12c-.51-.35-1.18.08-1.71.4s-1.64.87-2.16.63C81.35,43.73,82.24,42.93,82.15,42.15Z" style={{fill:"#d1dfe5"}}/>
                                            <path d="M49.31,30.06c.45-.43,1.38-.31,1.47.31a1.37,1.37,0,0,1-.1.57,9.24,9.24,0,0,0-.48,1.84,2.28,2.28,0,0,0,.51,1.78c.31.31.74.5.95.89a.35.35,0,0,1,.05.24.48.48,0,0,1-.23.29,18.83,18.83,0,0,0-4,4.05,3.73,3.73,0,0,1-1.29-1.78A5.4,5.4,0,0,0,45.72,37a3.85,3.85,0,0,0-.92-.83,8.15,8.15,0,0,1-2.25-2.66,1.24,1.24,0,0,1-.1-1.29c.1-.14.27-.24.33-.4a1,1,0,0,0,0-.48,1.66,1.66,0,0,1,.45-1.49.86.86,0,0,1,1.33.27,3,3,0,0,1,.13-1.23,1,1,0,0,1,.93-.67c.62.06.86.83,1.08,1.43s.73,1.74,1.28,1.9C49,31.81,48.76,30.6,49.31,30.06Z" style={{fill:"#d1dfe5"}}/>
                                            <path d="M67.87,51.5a24.48,24.48,0,0,0,.45,5,.94.94,0,0,1,0,.55.62.62,0,0,1-.49.32,1.54,1.54,0,0,1-1.35-.58,4.25,4.25,0,0,1-.73-1.4A15.14,15.14,0,0,1,65.1,48a2.27,2.27,0,0,1,.3-.93,2.11,2.11,0,0,1,1.21-.68L67.9,46a1.59,1.59,0,0,1,.83-.09c1,.26,0,1.6-.25,2.15A11.34,11.34,0,0,0,67.87,51.5Z" style={{fill:"#d1dfe5"}}/>
                                            <path d="M54.17,46.45A23.19,23.19,0,0,1,50.72,50a.85.85,0,0,0-.33.44.64.64,0,0,0,.19.57,1.58,1.58,0,0,0,1.42.44,3.82,3.82,0,0,0,1.45-.6,14.49,14.49,0,0,0,5.07-5.27,1.68,1.68,0,0,0-.2-2.23l-.78-1.14a1.6,1.6,0,0,0-.6-.61c-.94-.45-.95,1.26-1.14,1.82A10.94,10.94,0,0,1,54.17,46.45Z" style={{fill:"#d1dfe5"}}/>
                                            <path d="M61.32,51.53a1.28,1.28,0,0,1,1.63-.8,1.32,1.32,0,0,1,.77,1.68,1.25,1.25,0,0,1-1.63.8A1.32,1.32,0,0,1,61.32,51.53Z" style={{fill:"#3a464c"}}/>
                                            <path d="M57.37,50.08a.67.67,0,0,1,.85-.42.69.69,0,0,1,.4.88.66.66,0,0,1-.85.41A.7.7,0,0,1,57.37,50.08Z" style={{fill:"#3a464c"}}/>
                                            <path d="M66.77,69.2A2.23,2.23,0,0,0,65.52,69a6.49,6.49,0,0,0-1.24.34" style={{fill:"none",stroke:"#d1dfe5",strokeLinecap:"round",strokeMiterLimit:"10",strokeWidth:"0.41464965923842684px"}}/>
                                            <path d="M66.41,67.93a2.31,2.31,0,0,0-1.32-.17,5.82,5.82,0,0,0-1.29.39" style={{fill:"none",stroke:"#d1dfe5",strokeLinecap:"round",strokeMiterLimit:"10",strokeWidth:"0.41464965923842684px"}}/>
                                            <path d="M66.08,66.89a3.35,3.35,0,0,0-2.4.37" style={{fill:"none",stroke:"#d1dfe5",strokeLinecap:"round",strokeMiterLimit:"10",strokeWidth:"0.41464965923842684px"}}/>
                                            <path d="M44.42,60.36a5.15,5.15,0,0,1,1.89,2.11" style={{fill:"none",stroke:"#d1dfe5",strokeLinecap:"round",strokeMiterLimit:"10",strokeWidth:"0.41464965923842684px"}}/>
                                            <path d="M45.43,59.49a5.09,5.09,0,0,1,1.83,2.31" style={{fill:"none",stroke:"#d1dfe5",strokeLinecap:"round",strokeMiterLimit:"10",strokeWidth:"0.41464965923842684px"}}/>
                                            <path d="M46.29,59.06a9,9,0,0,1,1.82,2.24" style={{fill:"none",stroke:"#d1dfe5",strokeLinecap:"round",strokeMiterLimit:"10",strokeWidth:"0.41464965923842684px"}}/>
                                            <path d="M66.06,48.61a5.24,5.24,0,0,1-2.83.5,5.8,5.8,0,0,1-.81-.16,7.37,7.37,0,0,1-3-1.32,7.94,7.94,0,0,1-.81-.83,5.45,5.45,0,0,1-1.14-1.71,6.06,6.06,0,0,1-.26-1.9A24.65,24.65,0,0,0,60,44c.94.28,1.88.58,2.82.88s1.92.62,2.88,1l1.4.48c.24.08,1.08.22,1.14.52s-.73.81-.93,1A7.27,7.27,0,0,1,66.06,48.61Z" style={{fill:"#3a464c",opacity:"0.25"}}/>
                                            <path d="M56.79,37.54a7.65,7.65,0,0,1,9.84-4.8,8,8,0,0,1,4.65,10.13,7.63,7.63,0,0,1-9.83,4.8A8,8,0,0,1,56.79,37.54Z" style={{fill:"#f9fbfc"}}/>
                                            <path d="M58.85,36l.27-.08c1.06-.18,1.94.7,2.84,1.1s1.86.78,2.81,1.1,1.88.7,2.84,1,2.16.17,2.87,1a2,2,0,0,1,.17.23,3.18,3.18,0,0,1,.07,2.57A6.72,6.72,0,0,1,62,47l-.2-.08-.06,0-.07,0-.2-.07a7,7,0,0,1-4.23-8.9A3.06,3.06,0,0,1,58.85,36Z" style={{fill:"#102638"}}/>
                                            <path d="M63.7,38.14s-.51,5.44,1.21,8.9a5.6,5.6,0,0,1-2.58-.16S60,42,61.5,37.18C61.5,37.18,63,37.9,63.7,38.14Z" style={{fill:"#f9fbfc",opacity:"0.15"}}/>
                                            <path d="M60.83,36.94s-1.55,5.39.81,9.68A3.7,3.7,0,0,1,60.36,46s-2.79-3.62-.46-9.62Z" style={{fill:"#f9fbfc",opacity:"0.15"}}/>
                                        </g>
                                        <g id="Rocket">
                                                <path id="outerFire" d="M182.59,433.78H143s-16,12.19-8.13,41.2c0,0,4.61-12.44,11.38-9.51,0,0-.81,38.52,16.53,54.12,17.33-15.6,16.52-54.12,16.52-54.12,6.77-2.93,11.37,9.51,11.37,9.51C198.57,446,182.59,433.78,182.59,433.78Z" style={{fill:"#5BBBFF"}}/>
                                                <path id="innerFire" d="M172.75,433.78H153s-10.39,6.09-6.46,20.6c0,0,4.7-6.22,8.09-4.76,0,0-.41,19.26,8.26,27.06,8.67-7.8,8.26-27.06,8.26-27.06,3.39-1.46,8.26,4.76,8.26,4.76C183.31,439.87,172.75,433.78,172.75,433.78Z" style={{fill:"#3f51b5"}}/>
                                                <rect x="140.76" y="424.39" width="44.11" height="9.39" style={{fill:"gray"}}/>
                                                <path d="M126.09,382.75s-13.49.6-18.43-8.74c0,0-26.84,41.6-4.47,89,0,0,8.71-41.61,33-52.28Z" style={{fill:"#3f51b5"}}/>
                                                <path d="M199.58,382.75s13.49.6,18.43-8.74c0,0,26.84,41.6,4.47,89,0,0-8.71-41.61-33-52.28Z" style={{fill:"#004568"}}/>
                                                <path d="M184.91,424.39c10.71-20.23,17.77-41.57,17.77-80.77,0-62.32-39.84-112.85-39.84-112.85S123,281.3,123,343.62c0,39.2,7.06,60.54,17.77,80.77Z" style={{fill:"#fcfdf2"}}/>
                                                <path d="M136,414.71c1.48,3.29,3.08,6.5,4.76,9.68h44.15c1.68-3.18,3.27-6.39,4.76-9.68Z" style={{fill:"#3f51b5"}}/>
                                                <path d="M189.1,278.1c-11.55-28.64-26.26-47.33-26.26-47.33s-14.72,18.69-26.27,47.33Z" style={{fill:"#3f51b5"}}/>
                                                <path d="M158.4,418.5a228,228,0,0,0,4.44,44.5,228.48,228.48,0,0,0,4.43-44.5c0-24.57-2-44.49-4.43-44.49S158.4,393.93,158.4,418.5Z" style={{fill:"#3f51b5"}}/>
                                                <path d="M181.62,316.29c0,9.8-8.41,17.74-18.78,17.74s-18.79-7.94-18.79-17.74,8.41-17.75,18.79-17.75S181.62,306.48,181.62,316.29Z" style={{fill:"#3f51b5"}}/>
                                                <path d="M176.69,316.29c0,7.23-6.2,13.09-13.85,13.09S149,323.52,149,316.29s6.2-13.1,13.86-13.1S176.69,309.05,176.69,316.29Z" style={{fill:"#00536e"}}/>
                                            <g style={{mixBlendMode:"multiply"}}>
                                                <path d="M166.73,433.78h18.14v-9.39H167.18C167.09,427.64,166.93,430.78,166.73,433.78Z" style={{fill:"gray"}}/>
                                                <path d="M218,374c-4.94,9.34-18.43,8.74-18.43,8.74h0a134.52,134.52,0,0,1-8.5,28.72c23.06,11.73,31.4,51.52,31.4,51.52C244.85,415.61,218,374,218,374Z" style={{fill:"#3f51b5"}}/>
                                                <path d="M163.21,231.26l.2.27Z" style={{fill:"#fcfdf2"}}/>
                                                <path d="M162.9,230.85a.59.59,0,0,0,.08.11Z" style={{fill:"#fcfdf2"}}/>
                                                <path d="M202.68,343.62c0-55.28-31.32-101.24-38.41-110.93A219,219,0,0,1,189.1,278.1H162.84v20.44c10.37,0,18.78,7.94,18.78,17.75S173.21,334,162.84,334v40c2.32,0,4.22,17.9,4.41,40.7h22.42c.48-1.07,1-2.15,1.41-3.23a134.52,134.52,0,0,0,8.5-28.72A218.14,218.14,0,0,0,202.68,343.62Z" style={{fill:"#fcfdf2"}}/>
                                                <path d="M187.26,419.79q-1.14,2.31-2.35,4.6Q186.13,422.1,187.26,419.79Z" style={{fill:"#fcfdf2"}}/>
                                                <path d="M163.58,231.75l.69.93Z" style={{fill:"#fcfdf2"}}/>
                                                <path d="M189.67,414.72q-1.08,2.37-2.22,4.69Q188.59,417.09,189.67,414.72Z" style={{fill:"#fcfdf2"}}/>
                                                <path d="M164.27,232.69h0l-.69-.93-.17-.22-.2-.27c-.1-.12-.17-.22-.23-.3a.59.59,0,0,1-.08-.11l-.06-.08V278.1H189.1A219,219,0,0,0,164.27,232.69Z" style={{fill:"#3f51b5"}}/>
                                                <path d="M162.84,374v89a219.92,219.92,0,0,0,3.89-29.22c.2-3,.36-6.14.45-9.39h17.73q1.22-2.29,2.35-4.6l.19-.38q1.14-2.32,2.22-4.69H167.25C167.06,391.91,165.16,374,162.84,374Z" style={{fill:"#3f51b5"}}/>
                                                <path d="M181.62,316.29c0-9.81-8.41-17.75-18.78-17.75v4.65c7.65,0,13.85,5.86,13.85,13.1s-6.2,13.09-13.85,13.09V334C173.21,334,181.62,326.09,181.62,316.29Z" style={{fill:"#3f51b5"}}/>
                                                <path d="M176.69,316.29c0-7.24-6.2-13.1-13.85-13.1v26.19C170.49,329.38,176.69,323.52,176.69,316.29Z" style={{fill:"#00536e"}}/>
                                            </g>
                                            <g id="Paperly">
                                                <text/>
                                                <g id="PaperlyLogo">
                                                    <g id="Paperly_logo">
                                                        <g id="part3">
                                                            <path d="M170.42,310.6a.57.57,0,0,0-.29-.47l-.3-.14h0l-.3-.14-9.71-4.61c-.17-.08-.2,0-.08.1l4.45,5.15a1.51,1.51,0,0,0,.51.39l3,1.44.29.14.3.14,1.27.61.57.27c.15.07.28,0,.28-.2Z" style={{fill:"#fff"}}/>
                                                        </g>
                                                        <g id="part2">
                                                            <path d="M159.9,305.74c-.13-.16-.25-.12-.25.09v21.28c0,.21.11.25.25.09l2.7-3.2a1.24,1.24,0,0,0,.24-.66V309.5a1.14,1.14,0,0,0-.25-.66Z" style={{fill:"#fff"}}/>
                                                        </g>
                                                        <g id="part1">
                                                            <path d="M170.64,310.11c0-.21-.15-.3-.33-.2l-14,7.29a.2.2,0,0,0,0,.38l2.5,1.6a.74.74,0,0,0,.65,0l10.86-5.68a.68.68,0,0,0,.33-.55Z" style={{fill:"#31c4f3"}}/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div className="title">
                            <Hidden smDown>
                                <Typography variant="h3" align="center">
                                    <div style={{fontFamily:"titleFont"}}>
                                        <b>SKYROCKET YOUR PERFORMANCE WITH <span style={{color:"#1fa2ff"}}>PAPERLY</span></b>
                                    </div>
                                </Typography>
                            </Hidden>
                            <Hidden mdUp>
                                <Typography variant="h6" align="center">
                                    <div style={{  fontFamily:"titleFont",paddingLeft:'15px',paddingRight:"15px",paddingBottom:"20px"}}>
                                        <b>SKYROCKET YOUR PERFORMANCE WITH <span style={{color:"#1fa2ff"}}>PAPERLY</span></b>
                                    </div>
                                </Typography>
                            </Hidden>
                        </div>
                    </Grid>
                </Grid>
            </div>
    )
}
